.crafting-holder, .ingredients {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    margin-top: 10px;
    background: #C6C6C6;
    border: 2px solid #373737;
    border-right-color: #FFF;
    border-bottom-color: #FFF;
}

.ingredients-loading-text {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: Minecraft, sans-serif;
}

.ingredients {
    max-height: 700px;
    overflow-y: scroll;
}

.ingredients::-webkit-scrollbar {
    width: auto;
}

.ingredients::-webkit-scrollbar-track {
    background-color: #8B8B8B;
}

.ingredients::-webkit-scrollbar-thumb {
    height: 10px;
    background-color: #C6C6C6;
    border: 2px solid #373737;
    border-right-color: #FFF;
    border-bottom-color: #FFF;
}

.search-box {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    margin-bottom: 5px;
    overflow-x: auto;
}

.search-box p {
    margin-bottom: 0;
}

.search-box input {
    flex: 2;
    margin-left: 10px;
}
