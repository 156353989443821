.crafting-holder {
    position: relative;
    flex-direction: column;

    .crafting-title {
        font-family: Minecraft, sans-serif;
        margin-bottom: 5px;
    }
    
    .crafting-wrapper {
        display: flex;
        justify-content: center;
    }

    .crafting {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;

        .recipe {
            display: flex;

            .table-grid {
                display: flex;
                flex-wrap: wrap;

                width: 110px;

                &.grid-2x2 {
                    width: 72px;
                }
            }
        }

        .arrow {
            width: 32px;
            height: 26px;
            margin-left: 16px;
            margin-right: 16px;
            background-size: cover;
            background: url(../assets/arrow.png);
        }

        .flame {
            width: 32px;
            height: 32px;
            margin-bottom: 5px;
            background: url(../assets/flame.png);
        }

        .plus {
            width: 26px;
            height: 26px;
            margin-left: 16px;
            margin-right: 16px;
            background-image: url(../assets/plus.png);
        }

        .arrow, .flame, .plus {
            image-rendering: crisp-edges;
            image-rendering: pixelated;
        }
    }

    .grid-image-download-button {
        position: absolute;
        padding: 5px 7px;
        bottom: 0;
        right: 0;
        cursor: pointer;
    }
}

.crafting-tab-image {
    image-rendering: crisp-edges;
    image-rendering: pixelated;
}

.vertical {
    display: flex;
    flex-direction: column;
}

.horizontal {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.grid, .grid-large, .grid-furnace {
    position: relative;
    display: inline-block;
    text-align: center !important;
    background-color: #8B8B8B;
    border: 2px solid;
    border-top-color: #373737;
    border-right-color: #FFF;
    border-bottom-color: #FFF;
    border-left-color: #373737;
    vertical-align: middle;

    &::before {
        bottom: -2px;
        left: -2px;
    }

    &::after {
        top: -2px;
        right: -2px;
    }

    &::before, &::after {
        content: "";
        position: absolute;
        background-color: #8B8B8B;
        height: 2px;
        width: 2px;
        pointer-events: none;
    }
}

.grid-large img {
    z-index: -1;
    margin-top: 8px;
}

.grid, .grid-furnace {
    width: 36px;
    height: 36px;
}

.grid:hover, .grid-large:hover, .grid-furnace:hover {
    background-color: rgba(255, 255, 255, 0.4);
}

.grid-large {
    width: 48px;
    height: 48px;
}

.grid img, .grid-large img, .grid-furnace img {
    cursor: move;
}

.crafting-table-output {
    display: flex;
}

.crafting-table-output-count {
    position: absolute;
    bottom: 0;
    right: 5%;
    color: #ffffff;
    text-shadow: 0.125em 0.125em 0 #3F3F3F;
    font-family: Minecraft, sans-serif;
    user-select: none;
    pointer-events: none;

    font-smooth: none;
    -webkit-font-smoothing: none;
    -moz-osx-font-smoothing: none;
}

/* only for small */
@media screen and (max-device-width: 400px) {
    .crafting .arrow {
        margin-left: 0;
        margin-right: 0;
    }
}

.recipe > h6 {
    font-family: Minecraft, sans-serif;
}
