.options-row {
    margin-left: -15px;
    margin-right: -15px;
}

@media (min-width:768px) {
    .options-row {
        display: flex;
    }
}

.options-row .options-col {
    padding-left: 15px;
    padding-right: 15px;
}
