@keyframes shake {
    0%, 100% {
        transform: rotate(0deg);
    }
    10%, 50%, 70% {
        transform: rotate(-5deg);
    }
    20%, 40%, 80% {
        transform: rotate(5deg);
    }
    30%, 60%, 90% {
        transform: rotate(0deg);
    }
}

.item-shake {
    width: 125%;
    height: 125%;
    animation: shake 0.5s infinite linear;
    transform-origin: center;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    perspective: 1000px;
}