.navbar {
    font-family: Minecraft, sans-serif;
}

.navbar-brand {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.navbar-brand > img {
    max-width: 64px;
    padding: 0 10px;
}

.navbar-header ul.brand-menu {
    background-color: #222222;
    border: 0;
}

.navbar-header ul .navbar-brand {
    padding: 0 !important;
}

.navbar-header ul a {
    /* remove padding from the top only */
    padding: 0 20px !important;
}

/* only desktop */
@media only screen and (min-width: 992px) {
    .navbar-right li:last-child {
        padding-right: 10px;
    }

    .brand-menu {
        margin-top: 30px !important;
    }
}

/* only mobile */
@media only screen and (max-width: 992px) {
    .navbar-header > .dropdown {
        float: left;
    }
}
