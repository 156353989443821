h6 {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
}

@font-face {
    font-family: 'Minecraft';
    src: url(assets/Minecraft.woff) format('woff');
}

body {
    background: #eee;
    padding: 10px;
    color: #404040;
}

canvas {
    image-rendering: crisp-edges;
    image-rendering: pixelated;
}
